<template>
  <DgTable
    :data="widget"
    :theme="reportSettings.themeTable"
    :language="language"
    :heat-map="heatMap"
  />
</template>
<script>
import { mapState } from "vuex";
import { DEFAULT_LANGUAGE } from "@/constants/language";
import MixinFixWidget from "@/mixins/fixWidget";
import { DgTable } from "@engenharia/dashgoo-widgets";

export default {
  name: "RenderWidgetTable",
  mixins: [MixinFixWidget],
  components: {
    DgTable,
  },
  props: {
    widget: {
      type: Object,
      default: () => {},
    },
    widgetType: {
      type: String,
      default: () => "",
    },
    reportSettings: {
      type: Object,
      default: () => {},
    },
    heatMap: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState("dashboard", ["dashboardLanguage"]),
    language() {
      return this.dashboardLanguage || DEFAULT_LANGUAGE;
    },
  },
};
</script>
